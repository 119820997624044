import React from 'react';

import { motion } from "framer-motion";

import TopBar from '../../core/widgets/ui/TopBar';

export default function PanelTerms(props) {
    return (
        <div className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10">
                        <h2 class="text-2xl font-medium truncate ml-2 text-gray-900">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Terms and Condition
                        </h2>
                        <TopBar />
                    </div>
                    <hr className='mt-2' />
                    <div className='space-y-2 my-4 text-justify'>
                        <h1 className='text-3xl font-medium'>Terms and Condition</h1>
                        <p className='text-base font-medium'>Last updated date: 07, OCT 2024</p>
                        <p className='text-base'>Lorem ipsum dolor sit amet. In dignissimos dolore At consequuntur iusto aut natus iure. Ut ipsa voluptate 33 nemo quia est molestiae perspiciatis. Et quas quas et quae deleniti et esse iste eos explicabo nulla. Est molestiae magnam aut vitae ducimus aut nesciunt excepturi et modi repellat hic repellendus ipsam ut rerum deleniti.</p>
                        <p className='text-base'>Ut consequatur nihil id fugiat odio id nihil quas rem impedit optio. Sed odit iusto ut rerum doloremque ut repellendus vitae et consequatur aspernatur non amet quia et ratione velit id quaerat voluptate! Ut magnam quisquam eum exercitationem molestiae non rerum culpa ut ipsum vitae. Aut nisi debitis est galisum numquam cum laboriosam deleniti eum autem omnis.</p>
                        <p className='text-base'>Ad fuga perspiciatis eum iste illum et nostrum commodi qui exercitationem dignissimos non dolor consectetur. Non omnis deleniti et sunt vero sit dolorem itaque et adipisci dolorum ut debitis architecto nam nesciunt accusantium.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
