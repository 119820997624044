import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { partnerMeetStateAtom, partnerMeetConfigAtom, tokenAtom, userAtom } from '../../../core/config/atoms';

import { Video, VideoOff, Mic, MicOff } from 'lucide-react';

import { toast } from 'react-toastify';

export default function PanelInitMeet({ partner }) {

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);
    const [meetState, setMeetState] = useRecoilState(partnerMeetStateAtom);
    const [meetConfig, setMeetConfig] = useRecoilState(partnerMeetConfigAtom);

    const localStreamRef = useRef();

    const msgRef = useRef('');

    const [isVid, setIsVid] = useState(true);
    const [isAudio, setIsAudio] = useState(true);

    const createMeet = () => {
        const msg = msgRef.current.value;
        if (msg === "") {
            toast.error('Please fill all the details.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        } else {
            let ticketConfig = { ...meetConfig };
            ticketConfig.name = `${userData.user.fname}${userData.user.mname === "NA" ? "" : ` ${userData.user.mname}`}${userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}`;
            ticketConfig.lang = userData.langs[0];
            ticketConfig.msg = msg;
            ticketConfig.type = 'customer';
            ticketConfig.subtype = 'partner';
            ticketConfig.cid = userData._id;
            ticketConfig.priority = 'NA';
            setMeetConfig(ticketConfig);
            setMeetState(3);
        }
    }

    const toggleVideo = () => {
        const videoTracks = localStreamRef.current.srcObject.getVideoTracks();
        if (videoTracks.length > 0) {
            videoTracks[0].enabled = !isVid;
            setIsVid(!isVid);
        }
    }

    const toggleAudio = () => {
        const audioTracks = localStreamRef.current.srcObject.getAudioTracks();
        if (audioTracks.length > 0) {
            audioTracks[0].enabled = !isAudio;
            setIsAudio(!isAudio);
        }
    }

    const initData = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        localStreamRef.current.srcObject = stream;
    }

    useEffect(() => {
        initData();
    }, [meetState]);

    return (
        <div className='w-full h-full grid grid-cols-12 gap-4'>
            <div className='col-span-12 xl:col-span-8 h-full rounded-2xl flex items-center justify-center'>
                <div className='w-full pt-[56.25%] relative'>
                    <div className='w-full h-full'>
                        <video ref={localStreamRef} autoPlay playsInline muted className={`border-2 border-prime rounded-2xl object-fill absolute inset-0 w-full h-full`} />
                    </div>
                    <div className='w-[40%] h-[60px] flex items-center justify-center absolute bottom-0 left-[30%]'>
                        <div className={`w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center ${isVid ? 'bg-gray-800' : 'bg-red-600'}`} onClick={() => {
                            toggleVideo();
                        }}>
                            {isVid && <Video size={18} />}
                            {!isVid && <VideoOff size={18} />}
                        </div>
                        <div className={`w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center ${isAudio ? 'bg-gray-800' : 'bg-red-600'}`} onClick={() => {
                            toggleAudio();
                        }}>
                            {isAudio && <Mic size={18} />}
                            {!isAudio && <MicOff size={18} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-span-12 xl:col-span-4 flex items-center justify-center'>
                <div className='w-full bg-white shadow-inner rounded-2xl flex flex-col p-6 border'>
                    <div className='p-2'>
                        <span className='text-lg font-medium'>Ticket Form</span>
                    </div>
                    <div className='h-[1px] bg-gray-900' ></div>
                    <form className='w-full'>
                        <div className="col-span-6 mt-4">
                            <label className="block text-xs font-medium text-gray-700 font-mono">Ticket Message</label>
                            <textarea type="message" id="message" ref={msgRef} autoComplete="off" className="mt-1 p-2 block w-full h-40 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Message"></textarea>
                        </div>
                        <div className='flex justify-center mt-4'>
                            <button type="button" className="inline-flex justify-center w-[240px] py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-blue-700" onClick={() => { createMeet(); }}>
                                JOIN NOW
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}