import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, tokenAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import TopBar from '../../core/widgets/ui/TopBar';

import ServiceUtility from 'services/serviceUtility';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

export default function PanelFeedback(props) {

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);

    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const utilityService = new ServiceUtility();

    const formVSchema = Yup.object().shape({
        subject: Yup.string().required('This information is required'),
        message: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            subject: '',
            message: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "fname": userData.user.fname,
                "mname": userData.user.mname,
                "lname": userData.user.lname,
                "email": userData.user.email,
                "phone": userData.user.phone,
                "cid": userData.userId,
                "subject": values.subject,
                "message": values.message,
            }
            utilityService.sendFeedback(body, token).then((res) => {
                if (res.status) {
                    toast.success("Feedback sent!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    handleReset();
                } else {
                    toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                setSubmit(false);
            });
        }
    });

    return (
        <div className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10">
                        <h2 class="text-2xl font-medium truncate ml-2 text-gray-900">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Feedback
                        </h2>
                        <TopBar />
                    </div>
                    <hr className='mt-2' />
                    <form onSubmit={handleSubmit}>
                        <div className="mt-4">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                    <div className="shadow">
                                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Feedback Information</h3>
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Subject<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="subject" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. enquiry about call" value={values.subject} />
                                                    {(errors.subject && touched.subject) && <p className='text-xs text-red-400 mt-1'>{errors.subject}</p>}
                                                </div>

                                                <div className="col-span-6">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Your message<sup className="text-red-600">*</sup></label>
                                                    <textarea id="message" value={values.message} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-40 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Your message"></textarea>
                                                    {(errors.message && touched.message) && <p className='text-xs text-red-400 mt-1'>{errors.message}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                            <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "SUBMIT"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
