import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MoveLeft } from 'lucide-react';

import { useRecoilState } from 'recoil';
import { userAtom, meetTypeAtom, tokenAtom, partnerMeetStateAtom, partnerAtom } from '../../core/config/atoms';

import { toast } from 'react-toastify';
import Lottie from 'lottie-react';

import ServiceProfiles from '../../services/serviceProfiles';

import animEmpty from '../../assets/anim/anim-empty.json';

export default function PanelMeetMain() {

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);

    const [, setMeetType] = useRecoilState(meetTypeAtom);
    const [, setMeetState] = useRecoilState(partnerMeetStateAtom);
    const [, setPartnerLink] = useRecoilState(partnerAtom);

    const [partners, setPartners] = useState([]);
    const [partnersFillter, setPartnersFillter] = useState([]);

    const navigate = useNavigate();

    const profileService = new ServiceProfiles();

    const selectPartner = (partner) => {
        (async function () {
            var body = { 'pid': partner._id };
            var res = await profileService.getSubscriptionById(body, token);
            if (res['status']) {
                setPartnerLink(res.data.links);
                setMeetState(1);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const onChangeSearch = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case "search":
                let data = partnersFillter.filter((item) => (item.companyName.toLowerCase().includes(value.toLowerCase())));
                setPartners(data);
                break;
            default:
                break;
        }
    }

    const loadData = () => {
        (async function () {
            var res = await profileService.getAllPartner({}, token);
            if (res['status']) {
                setPartners(res.data);
                setPartnersFillter(res.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="grid grid-cols-12 grid-gap-2">
            <div className="col-span-12 mt-2">
                <div class="intro-y flex items-center justify-between h-10">
                    <div className='flex items-center text-2xl space-x-2'>
                        <div className='w-[28px] h-[28px] flex items-center justify-center cursor-pointer' onClick={() => {
                            setMeetType('');
                            navigate('/home/main');
                        }}>
                            <MoveLeft size={20} />
                        </div>
                        <h1 className='font-sans font-medium text-gray-900'>ISH Call Sign - Meet</h1>
                    </div>
                </div>
                <hr className='mt-2' />
            </div>
            <div className="col-span-12 mt-2 h-screen">
                <div className="bg-blue-100 h-[20%] w-full bgHead flex flex-col items-center justify-center relative" >
                    <div>
                        <h1 className='text-balck font-serif font-medium text-2xl'>Welcome, {userData.user.fname}</h1>
                        <p className='text-balck font-sans text-sm'>The web smart meet experience for our partners.</p>
                        <div className='w-full lg:w-[480px] h-12 mt-4 px-2 py-1 bg-white text-xs rounded flex items-center form-control'>
                            <span>
                                <i className="las la-search text-xl"></i>
                            </span>
                            <input type="text" name="search" placeholder='Search for partner.' className='w-full h-10 px-2 text-xs outline-none' onChange={onChangeSearch} />
                        </div>
                    </div>
                </div>
                <div className='bg-white w-full min-h-[60vh] overflow-hidden'>
                    {
                        partners.length === 0 && <div className='w-full h-full'>
                            <Lottie animationData={animEmpty} className="w-80 mx-auto mb-2" loop={true} />
                            <p className=" intro-y text-gray-900 lg:text-lg text-base text-center font-serif font-normal w-4/5 mx-auto">
                                No partners found.<br />
                                Here all the partners will be displayed
                            </p>
                        </div>
                    }
                    {
                        partners.length !== 0 && <>
                            <h2 className='pl-4 py-2 text-xl font-medium font-serif'>Partners</h2>
                            <div className='w-full grid grid-cols-1 lg:grid-cols-4 gap-6'>
                                {
                                    partners.map((partner, index) => {
                                        return <div className='col-span-1 px-4 py-2 transition-all hover:scale-105' key={index}>
                                            <div className='bg-white shadow rounded min-h-[158px] p-2 flex cursor-pointer space-x-2' onClick={() => { selectPartner(partner) }}>
                                                <img src={partner.profileMedia['fileurl']} alt='banner' className='w-[140px] h-[140px] rounded' />
                                                <div>
                                                    <h2 className='text-black font-serif text-lg font-medium mt-2'>{partner.companyName}</h2>
                                                    <p className='text-black text-base'>{partner.companyEmail}</p>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </>
                    }
                </div>
                <hr className='mt-6' />
                <footer className='w-full py-4'>
                    <p className="text-sm text-gray-400 font-serif text-center">&copy; 2024 India Signing Hands PVT. LTD.</p>
                </footer>
            </div>
        </div>
    )
}
