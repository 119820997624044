import { atom, DefaultValue } from 'recoil';

const localStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet(newValue => {
        if (newValue instanceof DefaultValue) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, JSON.stringify(newValue));
        }
    });
};

export const tempDataAtom = atom({
    key: "tempDataAtom",
    default: {},
});

export const modeAtom = atom({
    key: "modeAtom",
    default: false,
    effects_UNSTABLE: [
        localStorageEffect('modeAtom'),
    ]
});

export const authAtom = atom({
    key: "authAtom",
    default: 'default',
    effects_UNSTABLE: [
        localStorageEffect('authAtom'),
    ]
});

export const phoneAtom = atom({
    key: "phoneAtom",
    default: '',
});

export const userAtom = atom({
    key: "userAtom",
    default: {},
    effects_UNSTABLE: [
        localStorageEffect('userAtom'),
    ]
});

export const tokenAtom = atom({
    key: "tokenAtom",
    default: '',
    effects_UNSTABLE: [
        localStorageEffect('tokenAtom'),
    ]
});

export const userRoleAtom = atom({
    key: "userRoleAtom",
    default: "",
    effects_UNSTABLE: [
        localStorageEffect('userRoleAtom'),
    ]
});

export const meetTypeAtom = atom({
    key: "meetTypeAtom",
    default: '',
});

export const partnerAtom = atom({
    key: "partnerAtom",
    default: '',
});

export const partnerMeetStateAtom = atom({
    key: "partnerMeetStateAtom",
    default: 0,
});

export const partnerMeetConfigAtom = atom({
    key: "partnerMeetConfigAtom",
    default: {},
});