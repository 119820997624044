import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, authAtom, tokenAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";
import { MoveLeft, Pencil } from 'lucide-react';

import { motion } from "framer-motion";
import Lottie from 'lottie-react';
import { Dialog } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceAuth from '../../services/serviceAuth';
import ServiceOption from '../../services/serviceOption';
import ServiceUtility from '../../services/serviceUtility';
import ServiceLang from '../../services/serviceLang';

const allowedExtensions = ["png", "jpeg"];

export default function PanelSettings(props) {

    const userTypes = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' },
    ];

    const [userData, setUserData] = useRecoilState(userAtom);
    const [token, setToken] = useRecoilState(tokenAtom);

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);

    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isOpenDisable, setIsOpenDisable] = useState(false);

    const [contryOptions, setCountryOptions] = useState([]);
    const [conditionOptions, setConditionOptions] = useState([]);
    const [langOptions, setLangOptions] = useState([]);

    const [file, setFile] = useState(null);
    const [previews, setPreviews] = useState(null);

    const [submit, setSubmit] = useState(false);
    const [isOpenProfile, setIsOpenProfile] = useState(false);

    const navigate = useNavigate();
    const authService = new ServiceAuth();
    const optionService = new ServiceOption();
    const utilityService = new ServiceUtility();
    const langService = new ServiceLang();

    var A = { fullName: '', email: '', phone: '', gender: '', lang: '', country: '', condition: '' };
    var B = {};
    var D = { msg: '' };

    const formVSchemaA = Yup.object().shape({
        fullName: Yup.string().required('This information is required'),
        email: Yup.string().email('Please enter valid email address'),
        phone: Yup.string().required('This information is required').matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
        gender: Yup.string().required('This information is required'),
        lang: Yup.string().required('This information is required'),
        country: Yup.string().required('This information is required'),
        condition: Yup.string().required('This information is required'),
    });

    const formVSchemaD = Yup.object().shape({
        msg: Yup.string().required('This information is required'),
    });

    const formVSchemaB = Yup.object().shape({
    });

    const getInitValues = () => {
        if (isOpenEdit) {
            return A;
        }
        if (isOpenDisable) {
            return D;
        }
        if (isOpenProfile) {
            return B;
        }
        return A;
    }

    const getInitSchema = () => {
        if (isOpenEdit) {
            return formVSchemaA;
        }
        if (isOpenDisable) {
            return formVSchemaD;
        }
        if (isOpenProfile) {
            return formVSchemaB;
        }
        return formVSchemaA;
    }

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm, setErrors, setValues } = useFormik({
        initialValues: getInitValues(),
        validationSchema: getInitSchema(),
        enableReinitialize: true,
        onSubmit: values => {
            if (isOpenDisable) {
                if (values.msg !== "DISABLE") {
                    setErrors({ ...errors, msg: 'Please enter valid value.' });
                } else {
                    setSubmit(true);
                    let body = {
                        uid: userData._id
                    };
                    authService.deactiveUser(body, token).then((res) => {
                        if (res.status) {
                            resetForm();
                            setIsOpenDisable(false);
                            logout();
                        } else {
                            toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        setSubmit(false);
                    });
                }
            }
            if (isOpenEdit) {
                setSubmit(true);
                let names = values.fullName.split(' ');
                let body = {
                    uid: userData._id,
                    data: {
                        "disbilities": values.condition,
                        "langs": [values.lang],
                        "gender": values.gender,
                        "country": values.country
                    },
                    idpdata: {
                        "fname": names[0],
                        "mname": names.length > 2 ? names[1] : "NA",
                        "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                        "email": values.email,
                        "phone": values.phone
                    }
                };
                authService.userUpdate(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setIsOpenEdit(false);
                        setUserData(res.data);
                        toast.success('User profile updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        loadUserData();
                    } else {
                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    setSubmit(false);
                });
            }
            if (isOpenProfile) {
                if (file !== null) {
                    setSubmit(true);
                    var isMedia = false;
                    if (userData.profileMedia === undefined) {
                        isMedia = false;
                    } else {
                        if (userData.profileMedia.fileurl === undefined) {
                            isMedia = false;
                        } else {
                            isMedia = true;
                        }
                    }
                    if (!isMedia) {
                        var formDataA = new FormData();
                        formDataA.append("path", 'user');
                        formDataA.append("doc", file);

                        utilityService.uploadFile(formDataA, token).then((res) => {
                            if (res.status) {
                                let body = {
                                    uid: userData._id,
                                    data: {
                                        'profileMedia': res.data,
                                    },
                                    idpdata: {

                                    }
                                };
                                authService.userUpdate(body, token).then((resB) => {
                                    if (resB.status) {
                                        handleReset();
                                        setFile(null);
                                        setPreviews(null);
                                        setIsOpenProfile(false);
                                        setUserData(resB.data);
                                    } else {
                                        toast.error(resB.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    }
                                    setSubmit(false);
                                });
                            } else {
                                toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    } else {
                        var formDataA = new FormData();
                        formDataA.append("path", userData.profileMedia.fileurl);
                        formDataA.append("doc", file);

                        utilityService.replaceFile(formDataA, token).then((res) => {
                            if (res.status) {
                                let body = {
                                    uid: userData._id,
                                    data: {
                                        'profileMedia': res.data,
                                    },
                                    idpdata: {

                                    }
                                };
                                authService.userUpdate(body, token).then((resB) => {
                                    if (resB.status) {
                                        handleReset();
                                        setFile(null);
                                        setPreviews(null);
                                        setIsOpenProfile(false);
                                        setUserData(resB.data);
                                    } else {
                                        toast.error(resB.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    }
                                    setSubmit(false);
                                });
                            } else {
                                toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    }
                }
            }
        }
    });

    const handleFileChange = (e) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }

        setFile(inputFile);

        const reader = new FileReader();
        reader.onload = () => {
            setPreviews(reader.result);
        };
        reader.readAsDataURL(inputFile);
    };

    const deleteProfile = () => {
        let body = {
            'path': userData.profileMedia.fileurl
        }
        utilityService.deleteFile(body, token).then((res) => {
            let body = {
                uid: userData._id,
                data: {
                    'profileMedia': {},
                },
                idpdata: {

                }
            }
            authService.userUpdate(body, token).then((resB) => {
                if (resB.status) {
                    setUserData(resB.data);
                    handleReset();
                    setFile(null);
                    setPreviews(null);
                    setIsOpenProfile(false);
                } else {
                    toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                setSubmit(false);
            });
        });
    }

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        setToken('');
        navigate('/');
    }

    const loadUserData = () => {
        (async function () {
            let body = {
                'cid': userData._id,
            }
            var userRes = await authService.userGetById(body, token);
            setUserData(userRes.data);
        })();
    }

    const loadData = () => {
        (async function () {
            var cuRes = await optionService.getCountryOptions({}, '');
            setCountryOptions(cuRes.data);

            var coRes = await optionService.getConditionOptions({}, '');
            setConditionOptions(coRes.data);

            var langRes = await langService.getLangOptions({}, token);
            setLangOptions(langRes.data);
        })();
    }

    const openEdit = () => {
        setIsOpenEdit(true);
        setValues({ ...values, fullName: `${userData.user.fname}${userData.user.mname === "NA" ? "" : ` ${userData.user.mname}`}${userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}`, email: userData.user.email, phone: userData.user.phone, gender: userData.gender, lang: userData.langs[0], country: userData.country, condition: userData.disbilities });
    }

    useEffect(() => {
        loadData();
    }, [authStatus]);

    return (
        <>
            {
                isOpenEdit && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-screen md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-full lg:w-[600px] min-h-screen absolute right-0 p-[8px] lg:p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center text-2xl space-x-2'>
                                    <div className='w-[28px] h-[28px] flex items-center justify-center cursor-pointer' onClick={() => {
                                        setIsOpenEdit(false);
                                        handleReset();
                                        loadUserData();
                                    }}>
                                        <MoveLeft size={20} />
                                    </div>
                                    <h1 className='font-sans font-medium text-gray-900'>Profile Update</h1>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Full Name<sup className="text-red-600">*</sup></label>
                                        <input type="text" id="fullName" value={values.fullName} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Rajesh Kamal Hasan" onChange={handleChange} />
                                        {(errors.fullName && touched.fullName) && <p className='text-xs text-red-400 mt-1'>{errors.fullName}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Phone number<sup className="text-red-600">*</sup></label>
                                        <input type="text" id="phone" value={values.phone} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. 123456789" onChange={handleChange} />
                                        {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Email Address&nbsp;<sup className="text-gray-400">(optional)</sup></label>
                                        <input type="text" id="email" value={values.email} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. ramesh@gmail.com" onChange={handleChange} />
                                        {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Gender<sup className="text-red-600">*</sup></label>
                                        <select id="gender" onChange={handleChange} value={values.gender} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 appearance-none">
                                            <option value="" disabled="" selected="selected">Select</option>
                                            {
                                                userTypes.length !== 0 && userTypes.map((item) => {
                                                    return <option value={item.value} selected={values.uType == item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        {(errors.gender && touched.gender) && <p className='text-xs text-red-400 mt-1'>{errors.gender}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Country<sup className="text-red-600">*</sup></label>
                                        <select id="country" onChange={handleChange} value={values.country} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 appearance-none">
                                            <option value="" disabled="" selected="selected">Select</option>
                                            {
                                                contryOptions.length !== 0 && contryOptions.map((item) => {
                                                    return <option value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        {(errors.country && touched.country) && <p className='text-xs text-red-400 mt-1'>{errors.country}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Condition<sup className="text-red-600">*</sup></label>
                                        <select id="condition" onChange={handleChange} value={values.condition} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 appearance-none">
                                            <option value="" disabled="" selected="selected">Select</option>
                                            {
                                                conditionOptions.length !== 0 && conditionOptions.map((item) => {
                                                    return <option value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        {(errors.condition && touched.condition) && <p className='text-xs text-red-400 mt-1'>{errors.condition}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Language Preference<sup className="text-red-600">*</sup></label>
                                        <select id="lang" onChange={handleChange} value={values.lang} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 appearance-none">
                                            <option value="" disabled="" selected="selected">Select</option>
                                            {
                                                langOptions.length !== 0 && langOptions.map((item) => {
                                                    return <option value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        {(errors.lang && touched.lang) && <p className='text-xs text-red-400 mt-1'>{errors.lang}</p>}
                                    </div>
                                    <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-prime hover:bg-primeLight w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" />}
                                        {!submit && "UPDATE"}
                                    </button>
                                </form>
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2 text-gray-900">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer hidden lg:block" onClick={() => { props.onToggle(); }}></motion.i> User Profile
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                        <div className="col-span-12 mt-4">
                            <div className="intro-y w-full shadow-lg">
                                <div className='h-28 bg-prime rounded-t-lg relative'>
                                    <div className='h-28 bg-prime rounded-t-lg relative'>
                                        {
                                            userData.profileMedia !== undefined && <div className='w-20 lg:w-24 h-20 lg:h-24 rounded-full overflow-hidden border-4 border-white absolute bg-white' style={{ bottom: '-3rem', left: '1rem' }}>
                                                <img src={Object.keys(userData.profileMedia).length === 0 ? userData.gender === "Male" ? "https://storage.googleapis.com/ish-prod/ish-assets/male.png" : "https://storage.googleapis.com/ish-prod/ish-assets/female.png" : userData.profileMedia.fileurl} alt='User Image' className='w-20 lg:w-24 h-20 lg:h-24 rounded-full object-cover' />
                                            </div>
                                        }
                                        {
                                            userData.profileMedia === undefined && <div className='w-20 lg:w-24 h-20 lg:h-24 bg-white rounded-full flex items-center justify-center overflow-hidden border-4 border-white absolute' style={{ bottom: '-3rem', left: '1rem' }}>
                                                <img src={userData.gender === "Male" ? "https://storage.googleapis.com/ish-prod/ish-assets/male.png" : "https://storage.googleapis.com/ish-prod/ish-assets/female.png"} alt='User Image' className='w-20 lg:w-24 h-20 lg:h-24 rounded-full object-cover' />
                                            </div>
                                        }
                                        <div className='bg-prime w-7 h-7 rounded-full flex items-center justify-center border text-white border-white absolute cursor-pointer' style={{ bottom: '-3rem', left: '1rem' }} onClick={() => {
                                            setIsOpenProfile(true);
                                        }}>
                                            <Pencil size={14} />
                                        </div>
                                    </div>
                                </div>
                                <div className='pb-4 px-4 mb-4 rounded-b-lg'>
                                    <div className='flex items-center justify-between mt-1 mb-4'>
                                        <div className='text-2xl font-bold ml-24'>{userData.user.fname}{userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}</div>
                                        <div className='cursor-pointer bg-prime text-xs lg:text-sm font-serif py-1 lg:py-2 px-2 lg:px-4 rounded-md text-white' onClick={() => {
                                            openEdit();
                                        }}>Edit</div>
                                    </div>
                                    <div className='bg-gray-00 mt-2 p-4 rounded space-y-2'>
                                        <div className='flex items-center justify-between'>
                                            <div>
                                                <div className='text-xs text-gray-600'>USERNAME</div>
                                                <div className='text-lg text-black'>{userData.user.fname}{userData.user.mname === "NA" ? "" : ` ${userData.user.mname}`}{userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}</div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <div>
                                                <div className='text-xs text-gray-600'>Email ID</div>
                                                <div className='text-lg text-black'>{userData.user.email}</div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <div>
                                                <div className='text-xs text-gray-600'>Phone Number</div>
                                                <div className='text-lg text-black'>{userData.user.phone}</div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <div>
                                                <div className='text-xs text-gray-600'>Gender</div>
                                                <div className='text-lg text-black'>{userData.gender}</div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <div>
                                                <div className='text-xs text-gray-600'>Condition</div>
                                                <div className='text-lg text-black'>{userData.disbilities}</div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <div>
                                                <div className='text-xs text-gray-600'>Country</div>
                                                <div className='text-lg text-black'>{userData.country}</div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <div>
                                                <div className='text-xs text-gray-600'>Language Preference</div>
                                                <div className='text-sm text-black mt-2 flex space-x-2'>
                                                    {
                                                        userData.langs.map((lang) => {
                                                            return <div className='bg-prime text-white py-1 px-3 rounded-full'>{lang}</div>
                                                        })
                                                    }
                                                    {
                                                        userData.langs.length === 0 && <div>NA</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-4' />
                        <div className='hidden lg:block'>
                            <h2 className='text-2xl font-medium truncate'>Account Removal</h2>
                            <h2 className='text-base truncate'>Deleting this account means all your data will deleted.</h2>
                            <div className='flex'>
                                <div className='bg-red-400 cursor-pointer text-base font-serif mt-4 w-40 rounded px-2 py-2 text-white text-center' onClick={() => {
                                    setIsOpenDisable(true);
                                }}>
                                    Delete Account
                                </div>
                                <div className='ml-4 border-2 cursor-pointer border-red-400 text-red-400 text-base font-serif mt-4 w-40 rounded px-2 py-2 text-center' onClick={() => { logout() }}>
                                    Logout
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 h-screen w-full'>
                        <div className="w-full h-screen p-2 overflow-hidden">
                            <Dialog open={isOpenDisable} onClose={() => {
                                setIsOpenDisable(false);
                                resetForm();
                            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                    <div className='flex items-center justify-between'>
                                        <h3 className="text-xl font-semibold text-gray-900">
                                            Update Profile &nbsp;
                                        </h3>
                                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                            setIsOpenDisable(false);
                                            resetForm();
                                        }}>
                                            <i className="las la-times text-sm text-black"></i>
                                        </span>
                                    </div>
                                    <hr className='mt-2' />
                                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm text-gray-900">To delete account type the word <span className='font-bold'>DELETE</span><sup className="text-red-600">*</sup></label>
                                            <input type="text" id="msg" value={values.msg} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2.5 focus:outline-none focus:shadow focus:shadow-blue-500/50 focus:border-blue-400 uppercase" maxLength={7} placeholder="DELETE" onChange={handleChange} />
                                            {(errors.msg && touched.msg) && <p className='text-xs text-red-400 mt-1'>{errors.msg}</p>}
                                        </div>
                                        <div className='flex items-end'>
                                            <button type="submit" className={`text-white focus:ring-4 focus:outline-none font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-red-600 hover:bg-red-800`} disabled={submit}>
                                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                {!submit && "Delete"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Dialog>
                            <Dialog open={isOpenProfile} onClose={() => {
                                setIsOpenProfile(false);
                                handleReset();
                                setFile(null);
                                setPreviews(null);
                            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                                <div className="relative w-full max-w-2xl h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                    <div className='flex items-center justify-between'>
                                        <h3 className="text-xl font-semibold text-gray-900">
                                            Update Profile &nbsp;
                                        </h3>
                                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                            setIsOpenProfile(false);
                                            handleReset();
                                            setFile(null);
                                            setPreviews(null);
                                        }}>
                                            <i className="las la-times text-sm text-black"></i>
                                        </span>
                                    </div>
                                    <hr className='mt-2' />
                                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                        <div className="col-span-6 md:col-span-2">
                                            <div className="py-2 bg-white">
                                                <div>
                                                    {
                                                        file !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                            <div className="w-full flex items-center justify-center py-4">
                                                                <img src={previews} className='w-[400px] h-[400px]' />
                                                            </div>
                                                            <hr />
                                                            <div className="flex justify-between mt-2">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                    setFile(null);
                                                                    setPreviews(null);
                                                                }}>
                                                                    <span>Remove</span>
                                                                </label>
                                                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                    <span>Replace</span>
                                                                    <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        file === null && (userData.profileMedia !== undefined ? Object.keys(userData.profileMedia).length === 0 ? <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                        <span>Upload a file</span>
                                                                        <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                    </label>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">
                                                                    PNG, JPG, PDF up to 1MB
                                                                </p>
                                                            </div>
                                                        </div> : <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                            <div className="w-full flex items-center justify-center py-4">
                                                                <img src={userData.profileMedia.fileurl} className='w-[400px] h-[400px]' />
                                                            </div>
                                                            <hr />
                                                            <div className="flex justify-between mt-2">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                    deleteProfile();
                                                                }}>
                                                                    <span>Remove</span>
                                                                </label>
                                                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                    <span>Replace</span>
                                                                    <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                </label>
                                                            </div>
                                                        </div> : <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                        <span>Upload a file</span>
                                                                        <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                    </label>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">
                                                                    PNG, JPG, PDF up to 1MB
                                                                </p>
                                                            </div>
                                                        </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-full flex justify-center items-center'>
                                            <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                {!submit && "UPLOAD AND UPDATE"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
