import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom } from '../../../core/config/atoms';

import { toast } from 'react-toastify';

import { Bell } from 'lucide-react';

import { Menu, Transition } from '@headlessui/react';
import { motion } from "framer-motion";

export default function TopBar() {
    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const navigate = useNavigate();

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        navigate('/');
    }

    const changeMainMenu = (link) => {
        navigate(`/home/${link}`);
    }

    return (
        <div className='relative z-50 flex items-center'>
            <Menu>
                <Menu.Button>
                    <motion.div whileTap={{ scale: 0.90 }}>
                        <div className='w-9 h-9 rounded-full border-2 border-prime overflow-clip cursor-pointer mr-4'>
                            <img src={userData.user.profilePic ? userData.user.profilePic.fileurl : userData.gender === "Male" ? "https://storage.googleapis.com/ish-prod/ish-assets/male.png" : "https://storage.googleapis.com/ish-prod/ish-assets/female.png"} alt="Avatar Icon" />
                        </div>
                    </motion.div>
                </Menu.Button>
                <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0">
                    <Menu.Items>
                        <div
                            className='bg-white shadow-md rounded-md absolute' style={{ top: '48px', left: '-140px' }}>
                            <div>
                                <ul className='flex flex-col items-start p-2'>
                                    <Menu.Item>
                                        <li className='w-40 cursor-pointer hover:bg-blue-100 p-2 rounded-md' onClick={() => { changeMainMenu('setting') }}>
                                            <div className='flex items-center text-base'>
                                                <i className="las la-cog text-lg"></i>
                                                <p className='text-sm font-serif ml-2'>Settings</p>
                                            </div>
                                        </li>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <li className='w-40 cursor-pointer hover:bg-red-100 p-2 rounded-md' onClick={() => { logout() }}>
                                            <div className='flex items-center text-base'>
                                                <i className="las la-sign-out-alt text-lg"></i>
                                                <p className='text-sm font-serif ml-2'>Logout</p>
                                            </div>
                                        </li>
                                    </Menu.Item>
                                </ul>
                            </div>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
            <Bell size={22} className='mr-2 cursor-pointer' onClick={() => {
                toast.info('No new notification !', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }} />
        </div>
    )
}
