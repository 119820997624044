import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { userAtom, tokenAtom } from '../../core/config/atoms';

import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";

import { ChevronDown } from 'lucide-react';
import { ScrollArea } from "@/components/ui/scroll-area";

import { motion } from "framer-motion";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { toast } from 'react-toastify';

import TopBar from '../../core/widgets/ui/TopBar';

import ServicePack from 'services/servicePack';

export default function PanelSubscription(props) {

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);

    const { Razorpay } = useRazorpay();

    const [transactions, setTransactions] = useState([]);
    const [packs, setPacks] = useState([]);

    const [filter, setFilter] = useState({ 'customerId': userData.userId });

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isOpen, setIsOpen] = useState(false);
    const [isSub, setIsSub] = useState(false);

    const packService = new ServicePack();

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const buyPackage = (pack) => {
        var options = {
            key: "rzp_live_rPFcI9MHXgltQY",
            amount: pack.rate,
            currency: "INR",
            name: "India Signing Hands Private Limited",
            description: "ISH Call Sign Package Buy - " + pack.title,
            order_id: "order_9A33XWu",
            handler: (response) => {
                console.log(response);
                alert("Payment Successful!");
            },
            prefill: {
                name: `${userData.user.fname}${userData.user.mname === "NA" ? "" : ` ${userData.user.mname}`}${userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}`,
                email: userData.user.email,
                contact: userData.user.phone,
            },
            theme: {
                color: "#2C499E",
            },
        };
        console.log(options);

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            console.log(response);
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    }

    const getExportItems = () => {
        // let items = [];
        // tickets.forEach((item) => {
        //     items.push({
        //         ticketId: item.ticketId,
        //         ticketLang: item.ticketLang,
        //         ticketMessage: item.ticketMessage,
        //         ticketPriority: item.ticketPriority,
        //         waitTime: Moment(item.waitTime).format('DD, MMM yyyy - hh:mm:ss'),
        //         CallTime: Moment(item.CallTime).format('DD, MMM yyyy - hh:mm:ss'),
        //         EndTime: Moment(item.EndTime).format('DD, MMM yyyy - hh:mm:ss'),
        //         type: item.type,
        //         status: item.status,
        //         createdAt: item.createdAt,
        //     });
        // });
        // return items;
    }

    const exportToExcel = () => {
        // const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet, "tickets");
        // XLSX.writeFile(workbook, "tickets.xlsx");
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        // const blob = new Blob([data], { type: fileType })
        // const a = document.createElement('a')
        // a.download = fileName
        // a.href = window.URL.createObjectURL(blob)
        // const clickEvt = new MouseEvent('click', {
        //     view: window,
        //     bubbles: true,
        //     cancelable: true,
        // })
        // a.dispatchEvent(clickEvt)
        // a.remove()
    }

    const exportToCsv = () => {
        // let headers = ['ticketId,ticketLang,ticketMessage,ticketPriority,waitTime,CallTime,EndTime,type,status,createdAt'];
        // let usersCsv = tickets.reduce((acc, item) => {
        //     const { ticketId, ticketLang, ticketMessage, ticketPriority, waitTime, CallTime, EndTime, type, status, createdAt } = item
        //     acc.push([ticketId, ticketLang, ticketMessage, ticketPriority, Moment(item.waitTime).format('DD, MMM yyyy - hh:mm:ss'), Moment(item.CallTime).format('DD, MMM yyyy - hh:mm:ss'), Moment(item.EndTime).format('DD, MMM yyyy - hh:mm:ss'), type, status, createdAt].join(','))
        //     return acc
        // }, []);
        // downloadFile({
        //     data: [...headers, ...usersCsv].join('\n'),
        //     fileName: 'tickets.csv',
        //     fileType: 'text/csv',
        // });
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            // var res = await ticketService.getAllTicket(body, token);
            // if (res['status']) {
            //     setPageCurrent(res.data.currentPage);
            //     setPageTotal(res.data.totalPage);
            //     setTickets(res.data.data);
            // } else {
            //     toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            // }
        })();
    }

    const loadData = () => {
        (async function () {
            var res = await packService.getAllPack({ 'country': userData.country }, token);
            console.log(res);
            if (res['status']) {
                const sortedData = res.data.slice().sort((a, b) => {
                    return a.index - b.index;
                });
                setPacks(sortedData);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }

            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            // var res = await ticketService.getAllTicket(body, token);
            // if (res['status']) {
            //     setPageCurrent(res.data.currentPage);
            //     setPageTotal(res.data.totalPage);
            //     setTickets(res.data.data);
            // } else {
            //     toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            // }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, filter]);

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    return (
        <>
            {
                isOpen && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>Buy Packages</h1>
                                    <span onClick={() => {
                                        setIsOpen(false);
                                        loadData();
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <hr />
                                <ul className='mt-4 space-y-4'>
                                    {
                                        packs.length !== 0 && packs.map((pack) => {
                                            return <li>
                                                <div className='flex items-center justify-between border p-2 rounded'>
                                                    <div>
                                                        <h1 className='text-base font-bold'>{pack.title} - {pack.code}</h1>
                                                        <p className='text-sm'><b>{pack.value}</b> Min / <b>{pack.rate}</b> ₹</p>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight" onClick={() => { buyPackage(pack) }}>
                                                            BUY
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2 text-prime">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Manage Subscription
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                        <div className='max-w-[860px] mx-auto border mt-4 rounded'>
                            <div className='w-full p-4 flex items-center justify-between'>
                                <div>
                                    <h1 className='text-xl text-prime font-bold'>Wallet balence</h1>
                                    <p className='text-base'>Your current total wallet balence is : 0 Sec</p>
                                </div>
                                <div className='hover:bg-gray-50 cursor-pointer' onClick={() => { setIsSub(!isSub) }}>
                                    <ChevronDown />
                                </div>
                            </div>
                            {
                                isSub && <>
                                    <hr />
                                    <div className='w-full p-4 flex items-center justify-between'>
                                        <div>
                                            <h1 className='text-xl text-prime font-bold'>NA</h1>
                                        </div>
                                        <div>
                                            <button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight" onClick={() => { setIsOpen(true) }}>
                                                BUY
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="col-span-12 mt-16 px-2">
                            <div className="w-full shadow-md rounded-md border">
                                <table className="min-w-full divide-y divide-gray-200 px-4">
                                    <thead className="bg-gray-50">
                                        <tr className=''>
                                            <th scope="col" className="border-b border-gray-200" colSpan={5}>
                                                <div className='w-full grid grid-cols-12 gap-2'>
                                                    <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                        <div className='w-full flex col-span-12 items-center'>
                                                            <div className='w-[80px]'>
                                                                <p>Search :</p>
                                                            </div>
                                                            <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by transaction id" className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                        </div>
                                                    </form>
                                                    <div className='col-span-1 flex items-center'>
                                                        <DropdownMenu>
                                                            <DropdownMenuTrigger>
                                                                <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                            </DropdownMenuTrigger>
                                                            <DropdownMenuContent>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            <DropdownMenuItem>
                                                                                <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                    exportToExcel();
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                    </div>
                                                                                </li>
                                                                            </DropdownMenuItem>
                                                                            <DropdownMenuItem>
                                                                                <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                    exportToCsv();
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                    </div>
                                                                                </li>
                                                                            </DropdownMenuItem>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </DropdownMenuContent>
                                                        </DropdownMenu>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Transaction ID
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Package Details
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Amount
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Transaction Date
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {/* {
                                        tickets.length !== 0 && tickets.map((ticket) => {
                                            return <tr className="hover:bg-gray-100" key={ticket._id}>
                                                <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => {
                                                    setSelTicket(ticket);
                                                }}>
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div className='flex items-center text-prime uppercase'>
                                                            {ticket.ticketId} {`${getTicketTitle(ticket)}`}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{getType(ticket.type)}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {ticket.dept != null && Object.keys(ticket.dept).length !== 0 && <div>{ticket.dept.title}</div>}
                                                        {ticket.dept != null && Object.keys(ticket.dept).length === 0 && <div>NA</div>}
                                                        {ticket.dept == null && <div>{getTypeDept(ticket.type)}</div>}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{ticket.ticketPriority}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{Moment(ticket.waitTime).format('DD, MMM yyyy - hh:mm:ss')}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{(ticket.status === 'open' || ticket.status === 'hang' || ticket.status === 'live') ? "00:00:00" : getTimeDiff(ticket.CallTime, ticket.EndTime)}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {ticket.customerRating && <ReviewStars rating={ticket.customerRating.rating} />}
                                                    {!ticket.customerRating && <ReviewStars rating={0} />}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getStsCss(ticket.status)}`}>
                                                        {ticket.status.toUpperCase()}
                                                    </span>
                                                </td>
                                            </tr>
                                        })
                                    } */}
                                        {
                                            transactions.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                                <td className="px-6 py-4 whitespace-nowrap" colSpan="5">
                                                    <div className="text-sm text-red-600 text-center">
                                                        No data found.
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table >
                                <div className='flex py-2 px-4 items-center bg-blue-50'>
                                    <p className='text-sm font-serif'>Show rows per page</p>
                                    <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                    </select>
                                    <div className='flex-grow'></div>
                                    <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                                    <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                    <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                </div>
                            </div >
                        </div >
                    </div>
                </div>
            </div>
        </>
    )
}
